/* Importing Bootstrap SCSS file. */
// @import '~bootstrap/scss/bootstrap';

/* You can add global styles to this file, and also import other style files */
.modal-holder {
  animation-name: example;
  animation-duration: 0.3s;
}

@keyframes example {
  0% {
    transform: scale(0.5)
  }

  100% {
    transform: scale(1)
  }
}

.carousel-item {
  transition: opacity 0.7s ease !important;
  position: absolute !important;
  display: block !important;
  opacity: 0;
}

.carousel-item.active {
  position: relative !important;
  opacity: 1;
}

ngx-dropzone{
  border:2px dashed var(--bs-border-color) !important;
}

ngx-dropzone-preview {
  max-width: none !important;
  justify-content: normal !important;
  height: 80px !important;
  min-height: 80px !important;
  background-image: none !important;
  img {
    opacity: 1 !important;
  }

  ngx-dropzone-label {
    position: unset !important;
  }
}



/* Importing Bootstrap SCSS file. */
@import 'bootstrap/scss/bootstrap';
@import "primeng/resources/themes/lara-light-blue/theme.css";
@import "primeng/resources/primeng.css";
