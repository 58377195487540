// Lightbox
@import "~ngx-lightbox/lightbox.css";

// Select 2 
@import "~@ng-select/ng-select/themes/default.theme.css";

// Date Picker
@import '~flatpickr/dist/flatpickr.css';

// LeafLet Chart
@import 'leaflet/dist/leaflet.css';

// Emoji Picker
// @import '~@ctrl/ngx-emoji-mart/picker';
.emoji-mart {
  position: absolute;
  bottom: 20px;
  left: -80px;
}

.emoji-mart-preview {
  display: none;
}



// dropdown
.dropdown-toggle {
  &:after {
    display: none;
  }
}

.modal-backdrop {
  z-index: 1002 !important;
}

.dropdown-toggle::after {
  display: none;
}

.dropzone .dz-message {
  font-size: 24px;
  min-height: 150px !important;
  border: none !important;
  background: #fff !important;
  color: #495057 !important;
}

// ----- Advance Ui --------//
// Rating
.stars {
  font-size: 30px;
  color: var(--bs-gray-600);
}

.star {
  position: relative;
  display: inline-block;
}

.star_fill {
  color: $gray-500;
}

.full {
  color: #b0c4de;
}

.half {
  position: absolute;
  display: inline-block;
  overflow: hidden;
  color: var(--bs-gray-600);
}

.filled {
  color: #1e90ff;
}

.bad {
  color: #deb0b0;
}

.filled.bad {
  color: #ff1e1e;
}

.halfHeart {
  position: absolute;
  display: inline-block;
  overflow: hidden;
  color: red;
}

// Calendar
.flatpickr-calendar.open {
  z-index: 1061;
}

// Swich Btn
.switch.switch-small.checked>.switch-pane>span {
  color: #fff;
}

.switch.switch-small>.switch-pane>span {
  font-size: 14px;
}

// Ck Editer
.ck-editor__editable {
  min-height: 245px !important;
}

.custom-accordion {
  .accordion-list {
    padding: 0px;
    background-color: transparent;
  }
}

// select2 Css
.ng-select {
  .ng-select-container {
    background-color: $input-bg;
    border-color: $input-border-color;
    color: $input-color;

    .ng-value-container .ng-input>input {
      color: $input-color;
    }
  }

  &.ng-select-opened>.ng-select-container {
    background-color: $input-bg;
    border-color: $input-border-color;
  }

  .ng-select-disabled {
    background: #222736;
  }
}

.ng-dropdown-panel {
  border-color: $input-border-color;
  background-color: var(--#{$prefix}custom-white);
}

// .ng-dropdown-panel .ng-dropdown-panel-items .ng-option {
//     background-color: var(--#{$prefix}custom-white) !important;
//     color: var(--#{$prefix}body-color);
// }

.ng-dropdown-panel.ng-select-bottom {
  border-top-color: $input-border-color;
}

.ng-dropdown-panel.ng-select-top {
  border-bottom-color: $input-border-color;
}

//dropzone

.dropzone>.dropzone.dz-wrapper .dz-message {
  background: var(--#{$prefix}custom-white) !important;
  color: var(--#{$prefix}body-color) !important;
}

// ck editor
.ck.ck-toolbar {
  background-color: var(--#{$prefix}light) !important;
  border: 1px solid $input-border-color !important;
}

.ck-reset_all :not(.ck-reset_all-excluded *),
.ck.ck-reset_all {
  color: var(--#{$prefix}body-color) !important;
}

.ck.ck-editor__main>.ck-editor__editable {
  background-color: var(--#{$prefix}custom-white) !important;
  color: var(--#{$prefix}body-color) !important;
}

.ck.ck-editor__main>.ck-editor__editable:not(.ck-focused) {
  border-color: $input-border-color !important;
}

.ck.ck-button:not(.ck-disabled):hover,
a.ck.ck-button:not(.ck-disabled):hover,
.ck.ck-button.ck-on,
a.ck.ck-button.ck-on {
  background-color: var(--#{$prefix}light) !important;
}

.ck.ck-toolbar .ck.ck-toolbar__separator {
  background: $input-border-color !important;
}


// Dark Mode Css
[data-bs-theme="dark"] {
  .bs-datepicker {
    box-shadow: none !important;
  }

  .bs-datepicker-container {
    background-color: #2a3042 !important;

  }

  .bs-datepicker-head button {
    color: #c3cbe4 !important;
  }

  .bs-datepicker-body {
    border: 1px solid transparent;
  }

  .auth-logo .auth-logo-light {
    display: block;
  }

  .auth-logo .auth-logo-dark {
    display: none;
  }

  // Accordion
  .accordion {
    --bs-accordion-color: #fff;
    --bs-accordion-bg: transparent;
  }

  .accordion-button {
    color: var(--bs-body-color);
  }

  // NG SELECT
  .ng-dropdown-panel .ng-dropdown-panel-items .ng-option {
    background-color: #222736;
    color: #fff;

    &.ng-option-selected {
      background: #2a3042;
      color: #c3cbe4;
    }

    &:hover {
      background: #556ee6;
    }
  }

  .ng-select.ng-select-disabled>.ng-select-container {
    background-color: #b0c4de8e;
  }

  // SEET ALERT
  .swal2-html-container {
    color: #c3cbe4;
  }

  .swal2-popup.swal2-toast {
    background: var(--bs-secondary-bg);
    border: 1px solid #b0c4de8e;
  }
}

.page-link {
  display: inline-table;
}


//   vertical tab

.vertical-tab {
  ul {
    display: inline-block;
    position: relative;
    z-index: 1;
    border: 0;

    @media (max-width: 1024px) {
      display: block;
    }

    li {
      a {
        padding: 8px 50px;

        @media (max-width: 1440px) {
          padding: 8px 30px;
        }
      }
    }
  }

  .tab-content {
    position: absolute;
    top: 0;

    @media (max-width: 1024px) {
      position: inherit;
    }

  }
}


.ecommerce-checkout-tab {
  ul {
    display: inline-block;
    float: left;
    margin-right: 20px;
    border: 0;

    @media (max-width: 600px) {
      display: block;
      float: inherit;
    }
  }

  li {
    a {
      padding: 1px 80px;
    }
  }
}

.product-detai-imgs {
  ul {
    border: 0;

    a {
      border: 0 !important;
      width: 130px;

      @media (max-width: 425px) {
        width: 50px;
      }
    }
  }
}

.tooltip,
.tooltip-arrow {
  position: absolute;
}

[data-layout-size="boxed"] {
  .vertical-tab {
    ul {
      display: inline-block;
      position: relative;
      z-index: 1;
      border: 0;

      @media (max-width: 1024px) {
        display: block;
      }

      li {
        a {
          padding: 8px 50px;
        }
      }
    }
  }

}

.bs-datepicker-head {
  background-color: transparent !important;

}

.bs-datepicker-head button {
  color: black !important;
}

.owl-theme .owl-nav [class*=owl-] {
  width: 30px;
  height: 30px;
  line-height: 28px !important;
  font-size: 20px !important;
  border-radius: 50% !important;
  background-color: rgba(85, 110, 230, .25) !important;
  color: #556ee6 !important;
  justify-content: center !important;
  margin: 4px 8px !important;
  display: inline-flex !important;
  align-items: center !important;
}

.tab-bg {
  .nav-tabs {
    background-color: var(--#{$prefix}light);
    ;
  }

}


// popover
.bs-popover-top,
.bs-popover-bottom {
  margin-left: -8px !important;
}

.bs-popover-left .arrow,
.bs-popover-right .arrow {
  margin-top: -8px !important;
}

.custom-widget-nav {
  .nav-tabs {
    justify-content: flex-end;
    margin-top: -1.5rem;
  }
}

.custom-vertical-tabs {
  display: flex;
  align-items: flex-start;
  gap: 16px;

  .nav-tabs {
    flex-direction: column;

    @media (min-width: 992px) {
      flex-shrink: 0;
    }
  }

  .tab-content {
    @media (min-width: 992px) {
      flex-grow: 1;
    }
  }

  @media (max-width: 991px) {
    flex-direction: column;
    gap: 16px;

    .nav-tabs,
    .tab-content {
      width: 100%;
    }
  }
}


// nav-justify-center

.nav-justify-center {
  .nav-tabs {
    justify-content: center;
  }
}

// MODAL SCROLLABLE
.modal-dialog-scrollable {
  .modal-body {
    max-height: calc(100vh - 180px);
  }
}

// PREVIAZULIZADOR DE IMÁGENES
.content-preview-image {
  position: relative;
  max-height: 180px;
  width: 100%;
  display: flex;
  justify-content: center;
  align-items: center;

  .image {
    background-size: contain;
    position: relative;
    object-fit: contain;
    object-position: center;
    max-width: 100%;
    max-height: 180px;
    height: auto;
  }

  .btn-delete {
    position: absolute;
    top: 0;
    right: 0;
    border-radius: 50%;
  }
}

/* MODAL FULLSCREEN SCROLLABLE */
.modal-body-fullscreen-responsive {
  max-height: calc(100vh - 125px) !important;
  height: calc(100vh - 125px);
  // padding-top: 0.7rem !important;
  // padding-bottom: 0.7rem !important;

  .wizard {
    section {
      margin: 0 !important;
      padding: 0 !important;
      margin-top: 0.7rem !important;
    }

    .stepper-body {
      margin: 0 !important;
      padding: 0 !important;
    }
  }
}

// TRUNCATE
.text-truncate-3 {
  display: -webkit-box;
  -webkit-line-clamp: 3;
  /* Número máximo de líneas */
  -webkit-box-orient: vertical;
  overflow: hidden;
}

.text-truncate-4 {
  display: -webkit-box;
  -webkit-line-clamp: 4;
  /* Número máximo de líneas */
  -webkit-box-orient: vertical;
  overflow: hidden;
}

.text-truncate-5 {
  display: -webkit-box;
  -webkit-line-clamp: 5;
  /* Número máximo de líneas */
  -webkit-box-orient: vertical;
  overflow: hidden;
}

/* ACCORDION */
.accordion-customize {
  .panel-body {
    padding: 0 !important;
  }
}

/* WIZARD */
.wizard-visible {
  overflow: visible !important;
}


// BUSCADOR DE PALABRAS COINCIDENCIAS 
.highlight {
  font-weight: bold;
  color: #0080ff;
  /* Cambia el color según tu diseño */
}

[data-bs-theme="dark"]{
  .highlight{
    color: #00aefff6;
  }
}


// ESTILO SCROLLBAR
/* Estilo para el contenedor del scrollbar */
::-webkit-scrollbar {
  width: 14px;
  /* Ancho del scrollbar */
  height: 12px;
  /* Altura del scrollbar horizontal */
}

/* Estilo para la pista del scrollbar */
::-webkit-scrollbar-track {
  background: rgba(0, 0, 0, 0.1);
  /* Color de la pista */
  border-radius: 10px;
  /* Bordes redondeados */
}

/* Estilo para el thumb (la barra que se mueve) */
::-webkit-scrollbar-thumb {
  // background: rgba(0, 0, 0, 0.5); /* Color del thumb */
  background: $gray-600;
  /* Color del thumb */
  border-radius: 10px;
  /* Bordes redondeados */
}

/* Estilo al hacer hover sobre el thumb */
::-webkit-scrollbar-thumb:hover {
  background: $blue-600;
  /* Color más oscuro al hacer hover */
}


// DROP ZONE

.custom-dropzone {
  background: $body-tertiary-bg-dark;
  border-radius: 5px !important;
  overflow-y: auto;
  max-width: 100% !important;
  min-width: initial;
  width: 100% !important;
  padding: 10px;

  ngx-dropzone-remove-badge {
    background: $danger !important;
    color: $white !important;
  }

  .dropzone {
    position: relative;
    margin: inherit !important;
    min-height: initial !important;
    height: auto !important;
    padding: 12px 8px 25px 8px !important;
    background: $body-tertiary-bg-dark !important;
    border: 2px solid $indigo !important;
    border-style: dashed !important;

    display: block;
  }

  .dropzone-label {
    color: $light !important;
  }

  .dropzone-preview {
    height: initial !important;
    background: $body-tertiary-bg;
    // color: $white;
  }

  .container-preview {
    .dropzone-preview {
      position: relative !important;
      margin: initial !important;

      .content-preview {
        height: auto !important;

        .content-img {
          max-height: 180px;
          max-width: fit-content;
          min-width: 0 !important;
          width: auto !important;
          height: 100%;
  
          img {
            position: relative;
            max-height: inherit !important;
            height: inherit !important;
          }
        }

        audio,
        video {
          max-width: 100%;
          height: auto;
        }

        audio{
          height: 54px;
        }
      }
    }
  }
}

// PREVISUALIZADOR DE ARCHIVOS
.file-viewer{
  .item-viewer{
    position: relative;
    height: auto;
    width: auto;

    img{
      object-fit: contain;
    }

    .options{
      position: absolute;
      z-index: 100;
      border-radius: 50%;
      top: 5px;
      right: 5px;
      display: none;
      gap: 3px;
      flex-wrap: wrap;
      justify-content: end;
      align-items: center;

      .opt-circle{
        z-index: 100;
        border-radius: 50%;
        width: 35px;
        height: 35px;
        display: flex;
        justify-content: center;
        align-items: center;
      }
    }

  
  
    &:hover > .options,
    &:active > .options{
      display: flex;
    }
  }
}

.media-grid {
  display: grid;
  grid-template-columns: repeat(auto-fit, minmax(200px, 1fr));
  gap: 8px; /* Espacio entre elementos */


  .media-item {
    position: relative;
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    border-radius: 8px;
    overflow: hidden;
    box-shadow: 0 2px 4px rgba(0, 0, 0, 0.1);
    aspect-ratio: unset; /* Mantener proporción */
    max-height: 400px; /* Altura máxima */
    height: auto;
    
    .options{
      position: absolute;
      z-index: 100;
      border-radius: 50%;
      top: 5px;
      right: 5px;
      display: none;
      gap: 3px;
      flex-wrap: wrap;
      justify-content: end;
      align-items: center;

      .opt-circle{
        z-index: 100;
        border-radius: 50%;
        width: 35px;
        height: 35px;
        display: flex;
        justify-content: center;
        align-items: center;
      }
    }  
  
    &:hover > .options,
    &:active > .options{
      display: flex;
    }
  }
  
  .media-item img,
  .media-item video,
  .media-item audio {
    width: 100%;
    height: 100%;
    object-fit: cover; /* Asegurar que los archivos se ajusten correctamente */
    flex-grow: 1;
  }

  .media-item audio{
    object-fit: contain;
    height: 54px !important;
    width: 100% !important;
    margin: 8px !important;
  }

  .media-item .media-info {
    padding: 8px;
    text-align: center;
  }
  
  .media-item .media-info strong {
    display: block;
    margin-bottom: 4px;
    font-size: 14px;
  }
  
  .media-item .media-info small {
    color: #666;
  }
}

.cursor-pointer{
  cursor: pointer;
}